import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ProductsAttributesUpdate } from "../../products/attributes/components/ProductsAttributesUpdate";
import { ProductsListView } from "../../products/components/ProductsListView";
import { IProductsList } from "../../products/dto/IProducts";
import { IBaseData, IWorkflow, IWorkflowOtherData } from "../dto/IWorkflow";
import { WorkflowService } from "../service";
import { WorkflowStepper } from "./WorkflowStepper";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import step4PaginationSlice from "../../../store/workflow/step4PaginationSlice";

export const WorkflowStep4 = () => {
  const { search } = useLocation();
  const mainTable = true;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const [baseData, setBaseData] = useState<IBaseData | undefined>(undefined);
  const [visibleWorkers, setVisibleWorkers] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const currentPage = Number(
    +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  );
  const lightOrDarkMode = useSelector(
    (state: IAllStates) => state.lightOrDarkMode
  );
  const stepPagination = useSelector(
    (state: IAllStates) => state.step4Pagination as any
  );
  const [translatorList, setTranslatorList] = useState<any[]>([]);
  const [translatorData, setTranslatorData] = useState<any>({ products: [] });
  // const [pagination, setPagination] = useState({
  //   page: currentPage,
  //   perPage: 10,
  //   category: null,
  //   addedWay: "",
  //   status: "",
  //   brand: null,
  //   attribute: null,
  // });
  const [pagination, setPagination] = useState({
    ...(stepPagination as unknown as any),
    // page: currentPage,
    // perPage: 10,
  });
  const [page, setPage] = useState(currentPage);
  const [currentWorkflow, setCurrentWorkflow] = useState<IWorkflow | null>(
    null
  );
  const [showTranslateDialog, setShowTranslateDialog] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerDelete, setTriggerDelete] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser.roles.includes("ROLE_COMPANY_ADMIN");
  const [productsList, setProductsList] = useState<any[]>([]);
  const navigate = useNavigate();

  const handlePages = (updatePage: number) => {
    // navigate(`?page=${updatePage}`);
    setPagination({ ...pagination, page: updatePage });
    dispatch(
      step4PaginationSlice.actions.setStep4Pagination({
        ...(stepPagination as any),
        page: updatePage,
      })
    );
    setPage(updatePage);
  };

  const viewClickHandler = (id: number) => {
    navigate(`/app/products/view/base-data/${id}`);
  };
  const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues(productsList?.map((product: any) => product.id));
    } else {
      setCheckedValues([]);
    }
  };
  const changeFilterHandler = (name: string, value: string) => {
    setPagination((prev: any) => ({ ...prev, [name]: value }));
    dispatch(
      step4PaginationSlice.actions.setStep4Pagination({
        ...(stepPagination as any),
        [name]: value,
      })
    );
  };
  const assignTranslatorHandler = async () => {
    id &&
      (await WorkflowService.assignTranslatorAttributes(Number(id), {
        copyWriter: translatorData?.user,
        products: checkedValues,
        // lang: activeTab,
      })
        .then((response) => {
          if (response === "") {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_assigned"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const changeFinishHandler = async (finishedOrUnfinished: boolean) => {
    id &&
      (await WorkflowService.changeFinishedStatusAtt(Number(id), {
        isDone: finishedOrUnfinished,
        products: checkedValues,
      })
        .then((response) => {
          if (response) {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_updated_status"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const toForLinkFunc = (productId: number) => {
    return `/app/workflow/step4/${productId}/${id}`;
  };
  const editClickHandler = (e: any, productId: number) => {
    if (e.type === "click") {
      navigate(`/app/workflow/step4/${productId}/${id}`);
      setSelectedProductId(productId);
    } else if (e.type === "contextmenu") {
      window.open(`/app/workflow/step4/${productId}/${id}`, "_blank");
      setSelectedProductId(productId);
    }
  };
  const selectTranslatorHandler = (event: any) => {
    setTranslatorData((prev: any) => ({ ...prev, user: +event?.value }));
  };
  const handlePerPage = (e: any) => {
    setPagination((prev: any) => ({ ...prev, page: 1, perPage: e.value }));
    dispatch(
      step4PaginationSlice.actions.setStep4Pagination({
        ...(stepPagination as any),
        page: 1,
        perPage: e.value,
      })
    );
  };
  const checkForMainRoles = () => {
    if (isAdmin || isCompanyAdmin || isManager) {
      return true;
    } else {
      return false;
    }
  };

  const deleteHandler = (product: IProductsList) => {
    product?.id &&
      WorkflowService.deleteAttributeBaseDataById(product?.id, Number(id))
        .then((response) => {
          setTriggerDelete(true);
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
        })
        .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    WorkflowService.getWorkflowAttributesProducts(pagination, +id!)
      .then((response) => {
        const { data } = response;
        const { attribute, workflow, workers } =
          (response as unknown as IWorkflowOtherData)?.otherData ?? {};
        setBaseData(attribute);
        setCurrentWorkflow(workflow as IWorkflow);
        setProductsList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setPage(response?.page);
        setTranslatorList(workers as any);
        setVisibleWorkers((response as any)?.view === 1 ? true : false);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, currentPage, triggerDelete, selectedProductId, updateList]);
  // useEffect(() => {
  //   if (currentPage) {
  //     setPagination((prev: any) => ({ ...prev, page: currentPage }));
  //     dispatch(
  //       step4PaginationSlice.actions.setStep4Pagination({
  //         ...(stepPagination as any),
  //         page: currentPage,
  //       })
  //     );
  //     setPage(currentPage);
  //   }
  // }, [currentPage]);
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };
  return (
    <Card>
      <Card.Body>
        <WorkflowStepper
          stepPagination={stepPagination}
          step={"ATTRIBUTES"}
          precent={baseData?.percent}
          setSelectedProductId={setSelectedProductId}
          status={pagination?.status}
          showNextButton
          showMarkAsComplete={
            currentWorkflow?.status === "ATTRIBUTES" &&
            (isAdmin || isCompanyAdmin || isManager)
          }
          changeFilterHandler={changeFilterHandler}
          id={id}
          handlePerPage={handlePerPage}
        />
        <div className="card bg-light overflow-hidden shadow-none">
          <div className="card-body">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="mb-0">
                  {`${t("workflow.current")}`}:{" "}
                  <b className="text-success">{baseData?.percent ?? 0}%</b>
                </h6>
              </div>
              <div className="flex-shrink-0">
                <h6 className="mb-0">
                  {baseData?.done ?? 0}/{baseData?.total ?? 0}{" "}
                  {`${t("workflow.finished")}`}
                </h6>
              </div>
            </div>
          </div>
          <div className="progress">
            {/*//@ts-ignore*/}
            <div
              className="progress-bar bg-success"
              style={{ width: `${baseData?.percent ?? 0}%` }}
              aria-valuenow={baseData?.percent as number}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
        <Col xs={12}>
          <div className="table-responsive">
            {checkForMainRoles() && (
              <div className="d-flex justify-content-start align-items-center mb-1">
                {visibleWorkers && lightOrDarkMode === "dark" ? (
                  <>
                    <h6 className="m-0 p-0 me-1">
                      {t("workflow.copywriter")}:
                    </h6>
                    <Select
                      className="react-select-field me-1"
                      styles={{
                        option: (base, state) => ({
                          ...base,
                          backgroundColor: state.isSelected
                            ? "#007aff"
                            : "#262a2f",
                          ":hover": {
                            backgroundColor: state.isSelected
                              ? "#007aff"
                              : "black",
                          },
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 250,
                          zIndex: showTranslateDialog ? 0 : 9999,
                          ":hover": {
                            backgroundColor: "black",
                          },
                        }),

                        menu: (base) => ({
                          ...base,
                          backgroundColor: "#262a2f",
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: showTranslateDialog ? 0 : 9999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      onChange={selectTranslatorHandler}
                      isClearable={true}
                      options={translatorList?.map((translator) => ({
                        label: `${translator?.firstName} ${
                          translator?.lastName
                        } (${translator.assignment}  ${t(
                          "products.products"
                        ).toLowerCase()})`,
                        value: translator.id,
                      }))}
                    />
                  </>
                ) : (
                  <>
                    <h6 className="m-0 p-0 me-1">
                      {t("workflow.copywriter")}:
                    </h6>
                    <Select
                      className="react-select-field me-1"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 250,
                          zIndex: showTranslateDialog ? 0 : 9999,
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: showTranslateDialog ? 0 : 9999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      onChange={selectTranslatorHandler}
                      isClearable={true}
                      options={translatorList?.map((translator) => ({
                        label: `${translator?.firstName} ${
                          translator?.lastName
                        } (${translator.assignment}  ${t(
                          "products.products"
                        ).toLowerCase()})`,
                        value: translator.id,
                      }))}
                    />
                  </>
                )}
                {visibleWorkers && (
                  <button
                    onClick={assignTranslatorHandler}
                    className="btn  btn-secondary d-flex align-items-center me-2"
                    disabled={!translatorData?.user || !checkedValues?.length}
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.assign")}
                  </button>
                )}
                <button
                  onClick={() => changeFinishHandler(true)}
                  className="btn  btn-success d-flex align-items-center me-2"
                  disabled={
                    // translatorData?.user ||
                    !checkedValues?.length
                  }
                >
                  <i className="ri-share-box-fill me-1" />
                  {t("global.finished")}
                </button>
                <button
                  onClick={() => changeFinishHandler(false)}
                  className="btn  btn-danger d-flex align-items-center me-2"
                  disabled={
                    // translatorData?.user ||
                    !checkedValues?.length
                  }
                >
                  <i className="ri-share-box-fill me-1" />
                  {t("global.unfinished")}
                </button>
              </div>
            )}

            {!!productsList?.length ? (
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    {checkForMainRoles() && (
                      <th scope="col">
                        <input
                          className="form-check-input m-0 cursor-pointer"
                          checked={
                            checkedValues.length === productsList?.length
                          }
                          type="checkbox"
                          onChange={checkAll}
                        />
                      </th>
                    )}

                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("global.aenCode")}`}</th>
                    <th scope="col">{`${t("global.finished")}`}</th>
                    <th scope="col">{`${t("global.assign")}`}</th>
                    <th scope="col">{`${t("products.product")}`}</th>
                    <th scope="col">{`${t("products.category")}`}</th>
                    <th scope="col">{`${t("products.createdAt")}`}</th>
                    <th scope="col">{`${t("global.actions")}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {productsList?.map((product) => (
                    <ProductsListView
                      toForLinkFunc={toForLinkFunc}
                      workflow={true}
                      nameOfTable="baseMainWorkflow"
                      key={product?.id}
                      showViewButton={false}
                      showAssignColumn={true}
                      onEdit={editClickHandler}
                      onView={viewClickHandler}
                      product={product}
                      mainTable={mainTable}
                      deleteHandler={deleteHandler}
                      handleCheck={checkboxHandler}
                      checkedValues={checkedValues}
                    />
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyState />
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end ">
                {totalPages <= 1 ? (
                  ""
                ) : (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        {/* )} */}
      </Card.Body>
    </Card>
    // </PageTemplate>
  );
};
