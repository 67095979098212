import React from "react";
import { t } from "i18next";
import { Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

interface IMappingStepper {
  step: string;
}

export const MappingStepper = ({ step }: IMappingStepper) => {
  const { id } = useParams();
  const tabsList = [
    "SETTING_UP",
    "CATEGORY",
    "ATTRIBUTE",
    "VALUE",
    "FIELD",
    "DOCUMENT",
  ];
  const handleStepSteper = (status: string, id: number) => {
    switch (status) {
      case "SETTING_UP":
        return `/app/clients/mapping-update/${id}`;
      case "CATEGORY":
        return `/app/clients/mapping-category/${id}`;
      case "ATTRIBUTE":
        return `/app/clients/mapping-attribute/${id}`;
      case "VALUE":
        return `/app/clients/mapping-value/${id}`;
      case "FIELD":
        return `/app/clients/mapping-field/${id}`;
      case "DOCUMENT":
        return `/app/clients/mapping-document/${id}`;
      default:
        return `/app/clients/mapping-update/${id}`;
    }
  };

  return (
    <Col md={12}>
      <ul className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
        {tabsList?.map((stepName: string) => {
          return (
            <li className="nav-item" key={stepName}>
              <Link
                className={`nav-link ${step === stepName ? "active" : ""}`}
                to={handleStepSteper(stepName, Number(id))}
              >
                {t(`mapping.${stepName}`)}
              </Link>
            </li>
          );
        })}
      </ul>
    </Col>
  );
};
