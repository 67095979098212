import { t } from "i18next";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { Carousel } from "react-bootstrap";
import { CustomImageHandler } from "../../../../shared/components/CustomImageHandler";
import { useNavigate } from "react-router";

interface IProductsViewProps {
  currentProduct: IProductsList | IProducts;
}
export const BaseDataView = ({ currentProduct }: IProductsViewProps) => {
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const showCrawlerUrl = accessRights?.includes(
    "product_base_data_crawler_can_view"
  );
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const showImg =
    (currentProduct as IProductsList)?.productPictures?.length > 0;
  const navigate = useNavigate();

  return (
    <>
      <div className={`col-md-${showImg ? 9 : 12}`}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className={`col-md-4 my-3`}>
                <label htmlFor="eanCode">{t("products.ean_code")}</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  name="eanCode"
                  value={currentProduct?.eanCode}
                />
              </div>
              <div className="col-md-4 my-3">
                <label htmlFor="articleNumber">
                  {t("products.articleNumber")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="articleNumber"
                  disabled
                  value={currentProduct?.articleNumber}
                />
              </div>
              <div className="col-md-4 my-3">
                <label htmlFor="sapId">{t("products.sapId")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="sapId"
                  disabled
                  value={currentProduct?.sapId}
                />
              </div>
              {translationLanguageEnum?.map((language) => {
                return (
                  <>
                    <div className={`col-md-6 my-3`} key={language}>
                      <label htmlFor="title" className="required-field">
                        {t(`products.title_${language}`)}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        disabled
                        value={currentProduct?.translation?.[language]?.title}
                      />
                    </div>
                    <div className={`col-md-6 my-3`} key={language}>
                      <label htmlFor="subtitle">
                        {t(`products.subtitle_${language}`)}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="subtitle"
                        disabled
                        value={
                          currentProduct?.translation?.[language]?.subtitle
                        }
                      />
                    </div>
                  </>
                );
              })}
              <div className="my-3 col-md-4">
                <label htmlFor="category" className="required-field">
                  {t("products.select_main_category")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="category"
                  disabled
                  value={
                    (currentProduct as any)?.category?.parents?.find(
                      (parent: { level: number }) => parent?.level === 1
                    )?.title
                  }
                />
              </div>
              <div className="col-md-4 my-3">
                <label htmlFor="category-main" className="required-field">
                  {t("products.select_category")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="category-main"
                  disabled
                  value={(currentProduct as any)?.category?.title}
                />
              </div>
              <div className="my-3 col-md-4">
                <label htmlFor="category" className="required-field">
                  {t("products.brand")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="category"
                  disabled
                  value={(currentProduct as IProductsList)?.brand?.name}
                />
              </div>
              <div className={`col-md-6 my-3`}>
                <label htmlFor="website">{`${t("crawlers.website")}`}</label>
                <input
                  type="website"
                  className="form-control"
                  name="website"
                  disabled
                  value={currentProduct?.website}
                />
              </div>
              {showCrawlerUrl && (
                <div className="col-md-6 my-3">
                  <label htmlFor="url">{`${t("products.crawlerUrl")}`}</label>
                  <input
                    type="text"
                    name="crawlerUrl"
                    disabled
                    required
                    value={currentProduct?.crawlerUrl}
                    className="form-control"
                  />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-info" onClick={() => navigate(-1)}>
                {t("products.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showImg && (
        <>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body carousel-view">
                <Carousel>
                  {(currentProduct as IProductsList)?.productPictures?.map(
                    (productImage) => (
                      <Carousel.Item
                        key={productImage?.id}
                        className="position-relative"
                      >
                        <CustomImageHandler
                          path={`${productImage?.path}`}
                          classes="figure-img img-fluid rounded"
                          alt="Product"
                        />
                      </Carousel.Item>
                    )
                  )}
                </Carousel>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
