import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Login } from "../authentication/login/Login";
import { Signup } from "../authentication/register";
import { Companies } from "./companies";
import { Crawlers } from "./crawlers";
import { Dashboard } from "./dashboard";
import { Users } from "./users";
import { Profile } from "./profile";
import { Block } from "./block";
import { NotFound } from "../shared/errors/404";
import { Import } from "./import";
import { Clients } from "./clients";
import { ClientExport } from "./exports";
import { Category } from "./category/values";
import { ProductsCreate } from "./products/components/ProductsCreate";
import { Attributes } from "./attributes/names";
import { Brand } from "./brand";
import { CompanyDetails } from "./companyDetails";
import { AttributesUpdate } from "./attributes/names/components/AttributesUpdate";
import { AttributesCreate } from "./attributes/names/components/AttributesCreate";
import { AttributeValue } from "./attributes/values";
import { CategoryAttributes } from "./category/attributes";
import { CategoryAttributesEdit } from "./category/edit";
import { ProductsAttributes } from "./products/attributes";
import { ProductsBaseData } from "./products/baseData";
import { ProductsContent } from "./products/content";
import { ProductsAttributesUpdate } from "./products/attributes/components/ProductsAttributesUpdate";
import { BaseDataUpdate } from "./products/baseData/components/BaseDataUpdate";
import { ContentUpdate } from "./products/content/components/ContentUpdate";
import { ForgotPassword } from "../authentication/login/ForgotPassword";
import { ImportedProducts } from "./pendingProducts/imported";
import { CrawledProducts } from "./pendingProducts/crawled";
import { EditImportedBaseData } from "./pendingProducts/imported/components/EditImportedBaseData";
import { EditAttributesImported } from "./pendingProducts/imported/components/EditAttributesImported";
import { EditContentImported } from "./pendingProducts/imported/components/EditContentImported";
import { EditContentCrawled } from "./pendingProducts/crawled/components/EditContentCrawled";
import { EditCrawledBaseData } from "./pendingProducts/crawled/components/EditCrawledBaseData";
import { EditAttributesCrawled } from "./pendingProducts/crawled/components/EditAttributesCrawled";
import { ViewCrawledBaseData } from "./pendingProducts/crawled/components/ViewCrawledBaseData";
import { ViewImportedBaseData } from "./pendingProducts/imported/components/ViewImportedBaseData";
import { BaseDataViewAction } from "./products/baseData/components/BaseDataViewAction";
import { AttributesViewAction } from "./products/attributes/components/AttributesViewAction";
import { ViewAttributesCrawled } from "./pendingProducts/crawled/components/ViewAttributesCrawled";
import { ViewAttributesImported } from "./pendingProducts/imported/components/ViewAttributesImported";
import { ContentViewActions } from "./products/content/components/ContentViewActions";
import { ViewContentCrawled } from "./pendingProducts/crawled/components/ViewContentCrawled";
import { ViewContentImported } from "./pendingProducts/imported/components/ViewContentImported";
import { CategoryEdit } from "./category/values/components/CategoryEdit";
import { Content } from "./content";
import { EditContent } from "./content/components/EditContent";
import ErrorBoundary from "../shared/components/ErrorBoundary";
import { Translate } from "./translation";
import { AccessRights } from "./accessRights";
import { AccessRightsEdit } from "./accessRights/components/AccessRightsEdit";
import { ChatGptPrompt } from "./chatGptPrompt";
import { Glossary } from "./glossary";
import { WorkflowStep1 } from "./workflow/components/WorkflowStep1";
// import { Workflo } from "./workflow/components/WorkflowList";
import React, { useEffect, useState } from "react";
import { WorkflowStep2 } from "./workflow/components/WorkflowStep2";
import { WorkflowStep3 } from "./workflow/components/WorkflowStep3";
import { WorkflowStep4 } from "./workflow/components/WorkflowStep4";
import { WorkflowStep5 } from "./workflow/components/WorkflowStep5";
import { customNavigate } from "..";
import { AccessForbiden } from "../shared/errors/403";
import { ListProductsByAttribute } from "./attributes/names/components/ListProductsByAttribute";
import { PageTemplate } from "../shared/layout/PageTemplate";
import { t } from "i18next";
import { Workflow } from "./workflow";
import WorkflowStep6 from "./workflow/components/WorkflowStep6";
import WorkflowStep7 from "./workflow/components/WorkflowStep7";
import WorkflowStep8 from "./workflow/components/WorkflowStep8";
import { WorkflowService } from "./workflow/service";
import { Overview } from "./overview";
import { ContentWorkflow } from "./contentWorkflow";
import { ContentStep1 } from "./contentWorkflow/components/ContentStep1";
import { ContentStep2 } from "./contentWorkflow/components/ContentStep2";
import { ContentStep3 } from "./contentWorkflow/components/ContentStep3";
import { ContentStep5 } from "./contentWorkflow/components/ContentStep5";
import { ContentStep4 } from "./contentWorkflow/components/ContentStep4";
import { ContentStep6 } from "./contentWorkflow/components/ContentStep6";
import { WorkflowStep3Edit } from "./workflow/components/WorkflowStep3Edit";
import WorkflowStep4Edit from "./workflow/components/WorkflowStep4Edit";
import WorkflowStep5Edit from "./workflow/components/WorkflowStep5Edit";
import WorkflowStep6Edit from "./workflow/components/WorkflowStep6Edit";
import DialogBox from "../shared/components/DialogBox";
import { useCallbackPrompt } from "../shared/customHooks/useCallbackPrompt";
import { useSelector } from "react-redux";
import { IAllStates } from "../store/rootReducer";
import CategoryAssign from "./category/values/components/CategoryAssign";
import AttributesAssign from "./attributes/names/components/AttributesAssign";
import { MappingClients } from "./clientsMapping";
import CreateMapping from "./clientsMapping/components/CreateMapping";
import { UpdateMapping } from "./clientsMapping/components/UpdateMapping";
import { CategoryStep } from "./clientsMapping/components/CategoryStep";
import { AttributeStep } from "./clientsMapping/components/AttributeStep";
import { ValueStep } from "./clientsMapping/components/ValueStep";
import { FieldStep } from "./clientsMapping/components/FieldStep";
import { Api } from "./api";
import { CreateApi } from "./api/components/CreateApi";
import { FirstStep } from "./api/components/FirstStep";
import { SecondStep } from "./api/components/SecondStep";
import { ThirdStep } from "./api/components/ThirdStep";
import { BlockTest } from "./blockDocument";
import { MappingImportClients } from "./clients/components/MappingImportClients";
import { DocumentStep } from "./clientsMapping/components/DocumentStep";

export function BaseRoutes() {
  const changeLocation = useSelector(
    (state: IAllStates) => state.changeLocation
  );
  const { id } = useParams();
  const [pageTitle, setPageTitle] = useState("");
  const { pathname } = useLocation();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(changeLocation);
  (customNavigate as any).navigate = useNavigate();
  useEffect(() => {
    switch (true) {
      case pathname === "/app/dashboard":
        return setPageTitle(t("side_bar.dashboard"));
      case pathname === "/app/workflow":
        return setPageTitle(t("side_bar.workflow"));
      case pathname.includes("/app/content-workflow"):
        return setPageTitle(t("side_bar.content_workflow"));
      case pathname.includes("/app/products/base-data"):
        return setPageTitle(t("side_bar.products_base_data"));
      case pathname.includes("/app/products/attributes"):
        return setPageTitle(t("side_bar.products_attributes"));
      case pathname.includes("/app/products/content"):
        return setPageTitle(t("side_bar.products_content"));
      case pathname.includes("/app/products/edit-content/"):
        return setPageTitle(t("products.content_update"));
      case pathname.includes("/app/export"):
        return setPageTitle(t("side_bar.client_export"));
      case pathname.includes("/admin/api"):
        return setPageTitle(t("side_bar.api"));
      case pathname.includes("/app/glossary"):
        return setPageTitle(t("access_rights.glossary_can"));
      case pathname.includes("app/chatgpt-prompt"):
        return setPageTitle(t("chatGpt.chatGpt_prompt"));
      case pathname.includes("app/overview"):
        return setPageTitle(t("workflow.OVERVIEW"));
      case pathname.includes("/app/translate"):
        return setPageTitle(t("side_bar.translate"));
      case pathname.includes("/admin/content-block"):
        return setPageTitle(t("block.content_block"));
      case pathname.includes("/admin/document-block"):
        return setPageTitle(t("document_block.block"));
      case pathname.includes("/admin/brand"):
        return setPageTitle(t("side_bar.brand"));
      case pathname.includes("/app/clients"):
        return setPageTitle(t("side_bar.clients"));
      case pathname.includes("/admin/access-rights"):
        return setPageTitle(t("side_bar.access_rights"));
      case pathname.includes("/admin/crawlers"):
        return setPageTitle(t("side_bar.crawlers"));
      case pathname.includes("/admin/companies"):
        return setPageTitle(t("side_bar.companies"));
      case pathname.includes("/app/users"):
        return setPageTitle(t("side_bar.users"));
      case pathname.includes("/app/company/details"):
        return setPageTitle(t("side_bar.company_details"));
      case pathname.includes("/admin/category/values"):
        return setPageTitle(t("side_bar.category"));
      case pathname.includes("/admin/category/attributes"):
        return setPageTitle(t("side_bar.categoryAttributes"));
      case pathname.includes("/admin/attributes/names"):
        return setPageTitle(t("side_bar.attributes"));
      case pathname.includes("/admin/attributes/assign"):
        return setPageTitle(t("side_bar.attributes"));
      case pathname.includes("/app/page-content"):
        return setPageTitle(t("side_bar.content"));
      case pathname.includes("/app/pending-products/imported"):
        return setPageTitle(t("side_bar.imported_products"));
      case pathname.includes("/app/pending-products/crawled"):
        return setPageTitle(t("side_bar.crawled_products"));
      case pathname.includes("/app/import"):
        return setPageTitle(t("side_bar.import"));
      case pathname.includes("app/variations/list/"):
        return setPageTitle(t("side_bar.client_export"));
      case pathname.includes("admin/attributes/value/"):
        return setPageTitle(t("side_bar.attributes"));
      case pathname.includes("app/products/view/base-data/"):
        return setPageTitle(t("products.base_data_view"));
      // case pathname.includes("app/content-workflow"):
      //   return setPageTitle(t("side_bar.content_workflow"));
      case pathname.includes("app/products/view/attributes/"):
        return setPageTitle(t("products.attributes_view"));
      case pathname.includes("app/products/view/content/"):
        return setPageTitle(t("products.content_view"));
      case pathname.includes("admin/attributes/list/"):
        return setPageTitle(t("attributes.list_of_products_by_attribute"));
      case pathname.includes("app/products/create"):
        return setPageTitle(t("side_bar.products_create"));
      case pathname.includes("admin/attributes/create"):
        return setPageTitle(t("attributes.attribute_create"));
      case pathname.includes("app/workflow/step"):
        WorkflowService.getWorkflowById(+pathname.split("/").slice(-1)[0]).then(
          (res) => {
            return setPageTitle(t("side_bar.workflow") + ` ${res.name}`);
          }
        );
        break;
      case pathname.includes("app/profile"):
        return setPageTitle(t("side_bar.profile"));
      // Expected output: "Mangoes and papayas are $2.79 a pound."
      default:
        // setPageTitle(pathname?.split("/").join(" "))
        setPageTitle("invalid");
      //
    }
  }, [pathname]);
  const hideSidebar = ["/", "login", "invalid", "invalid"];
  return (
    <>
      <DialogBox
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      {hideSidebar.includes(pageTitle) ? (
        <ErrorBoundary key={pathname}>
          <Routes>
            <Route path="/register" element={<Signup />} />
            <Route path="/" element={<Login />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/403" element={<AccessForbiden />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      ) : (
        <PageTemplate title={pageTitle}>
          <ErrorBoundary key={pathname}>
            <Routes>
              {/* <Route path="/" element={<Login />} /> */}

              <Route path="/app/dashboard" element={<Dashboard />} />
              <Route path="/app/clients" element={<Clients />} />
              <Route
                path="/app/clients/mapping-list/:id"
                element={<MappingClients />}
              />
              <Route
                path="/app/clients/import-mapping/:id"
                element={<MappingImportClients />}
              />
              <Route
                path="/app/clients/mapping-create/:id"
                element={<CreateMapping />}
              />
              <Route
                path="/app/clients/mapping-update/:id"
                element={<UpdateMapping />}
              />
              <Route
                path="/app/clients/mapping-category/:id"
                element={<CategoryStep />}
              />
              <Route
                path="/app/clients/mapping-attribute/:id"
                element={<AttributeStep />}
              />
              <Route
                path="/app/clients/mapping-value/:id"
                element={<ValueStep />}
              />
              <Route
                path="/app/clients/mapping-field/:id"
                element={<FieldStep />}
              />
              <Route
                path="/app/clients/mapping-document/:id"
                element={<DocumentStep />}
              />
              <Route path="/app/export" element={<ClientExport />} />
              <Route path="/admin/api" element={<Api />} />
              <Route path="/admin/api-create" element={<CreateApi />} />
              <Route path="/admin/api-update/:id" element={<FirstStep />} />
              <Route path="/admin/api-export/:id" element={<SecondStep />} />
              <Route path="/admin/api-test/:id" element={<ThirdStep />} />
              <Route path="/app/overview" element={<Overview />} />
              <Route path="/app/glossary" element={<Glossary />} />
              <Route path="/app/translate" element={<Translate />} />
              <Route path="/app/chatgpt-prompt" element={<ChatGptPrompt />} />
              <Route
                path="/app/products/attributes"
                element={<ProductsAttributes />}
              />
              <Route
                path="/app/products/attributes/:id"
                element={<ProductsAttributesUpdate />}
              />
              <Route
                path="/app/products/view/attributes/:id"
                element={<AttributesViewAction />}
              />
              <Route
                path="/app/products/base-data"
                element={<ProductsBaseData />}
              />
              <Route
                path="/app/products/content"
                element={<ProductsContent />}
              />
              <Route path="/app/products/create" element={<ProductsCreate />} />
              <Route
                path="/app/products/base-data/:id"
                element={<BaseDataUpdate />}
              />
              <Route
                path="/app/products/view/base-data/:id"
                element={<BaseDataViewAction />}
              />
              <Route
                path="/app/products/edit-content/:id"
                element={<ContentUpdate />}
              />
              <Route
                path="/app/products/view/content/:id"
                element={<ContentViewActions />}
              />
              <Route
                path="/app/pending-products/imported"
                element={<ImportedProducts />}
              />
              <Route
                path="/app/pending-products/imported/base-data/:id"
                element={<EditImportedBaseData />}
              />
              <Route
                path="/app/pending-products/imported/attributes/:id"
                element={<EditAttributesImported />}
              />
              <Route
                path="/app/pending-products/imported/content/:id"
                element={<EditContentImported />}
              />
              <Route
                path="/app/pending-products/crawled"
                element={<CrawledProducts />}
              />
              <Route
                path="/app/pending-products/crawled/base-data/:id"
                element={<EditCrawledBaseData />}
              />
              <Route
                path="/app/pending-products/crawled/attributes/:id"
                element={<EditAttributesCrawled />}
              />
              <Route
                path="/app/pending-products/crawled/content/:id"
                element={<EditContentCrawled />}
              />
              <Route
                path="/app/pending-products/crawled/view/base-data/:id"
                element={<ViewCrawledBaseData />}
              />
              <Route
                path="/app/pending-products/crawled/view/attributes/:id"
                element={<ViewAttributesCrawled />}
              />
              <Route
                path="/app/pending-products/crawled/view/content/:id"
                element={<ViewContentCrawled />}
              />
              <Route
                path="/app/pending-products/imported/view/base-data/:id"
                element={<ViewImportedBaseData />}
              />
              <Route
                path="/app/pending-products/imported/view/attributes/:id"
                element={<ViewAttributesImported />}
              />
              <Route
                path="/app/pending-products/imported/view/content/:id"
                element={<ViewContentImported />}
              />
              <Route path="/app/import" element={<Import />} />
              <Route path="/app/users" element={<Users />} />
              <Route path="/app/profile" element={<Profile />} />
              <Route path="/app/workflow" element={<Workflow />} />
              <Route
                path="/app/content-workflow"
                element={<ContentWorkflow />}
              />
              <Route
                path="/app/workflow/step1/:id"
                element={<WorkflowStep1 />}
              />
              <Route
                path="/app/content-workflow/setting-up/:id"
                element={<ContentStep1 />}
              />
              <Route
                path="/app/workflow/step2/:id"
                element={<WorkflowStep2 />}
              />
              <Route
                path="/app/content-workflow/base/:id"
                element={<ContentStep2 />}
              />
              <Route
                path="/app/workflow/step3/:id"
                element={<WorkflowStep3 />}
              />
              <Route
                path="/app/workflow/step3/:productId/:id"
                element={<WorkflowStep3Edit />}
              />
              <Route
                path="/app/content-workflow/copywriting/:id"
                element={<ContentStep3 />}
              />
              <Route
                path="/app/workflow/step4/:id"
                element={<WorkflowStep4 />}
              />
              <Route
                path="/app/workflow/step4/:productId/:id"
                element={<WorkflowStep4Edit />}
              />
              <Route
                path="/app/content-workflow/translation/:id"
                element={<ContentStep4 />}
              />
              <Route
                path="/app/workflow/step5/:id"
                element={<WorkflowStep5 />}
              />
              <Route
                path="/app/workflow/step5/:productId/:id"
                element={<WorkflowStep5Edit />}
              />
              <Route
                path="/app/content-workflow/overview/:id"
                element={<ContentStep5 />}
              />
              <Route
                path="/app/workflow/step6/:id"
                element={<WorkflowStep6 />}
              />
              <Route
                path="/app/workflow/step6/:productId/:id"
                element={<WorkflowStep6Edit />}
              />
              <Route
                path="/app/content-workflow/export/:id"
                element={<ContentStep6 />}
              />
              <Route
                path="/app/workflow/step7/:id"
                element={<WorkflowStep7 />}
              />
              <Route
                path="/app/workflow/step8/:id"
                element={<WorkflowStep8 />}
              />

              <Route path="/app/company/details" element={<CompanyDetails />} />
              <Route path="/admin/companies" element={<Companies />} />
              <Route path="/admin/crawlers" element={<Crawlers />} />
              <Route path="/admin/category/values" element={<Category />} />
              <Route
                path="/admin/category/values/assign"
                element={<CategoryAssign />}
              />
              <Route
                path="/admin/category/values/create"
                element={<CategoryEdit />}
              />
              <Route
                path="/admin/category/values/edit/:id"
                element={<CategoryEdit />}
              />
              <Route
                path="/admin/category/attributes"
                element={<CategoryAttributes />}
              />
              <Route path="/app/page-content" element={<Content />} />
              <Route path="/app/page-content/:id" element={<EditContent />} />
              {/* <Route path="/app/products/content/:lng/:id" element={<ContentUpdate/>}/> */}
              <Route
                path="/admin/category/attributes/edit/:id"
                element={<CategoryAttributesEdit />}
              />
              <Route path="/admin/brand" element={<Brand />} />
              <Route path="/admin/attributes/names" element={<Attributes />} />

              <Route
                path="/admin/attributes/assign"
                element={<AttributesAssign />}
              />
              <Route
                path="/admin/attributes/names/update/:id"
                element={<AttributesUpdate />}
              />
              <Route
                path="/admin/attributes/value/:id"
                element={<AttributeValue />}
              />
              <Route
                path="/admin/attributes/list/:id"
                element={<ListProductsByAttribute />}
              />
              <Route
                path="/app/export/list/:id"
                element={<ListProductsByAttribute />}
              />
              <Route
                path="/app/variations/list/:id"
                element={<ListProductsByAttribute />}
              />
              <Route path="/admin/access-rights" element={<AccessRights />} />
              <Route
                path="/admin/access-rights/:id"
                element={<AccessRightsEdit />}
              />
              <Route
                path="/admin/attributes/create"
                element={<AttributesCreate />}
              />
              <Route path="/admin/content-block" element={<Block />} />
              <Route path="/admin/document-block" element={<BlockTest />} />
            </Routes>
          </ErrorBoundary>
        </PageTemplate>
      )}
    </>
  );
}
