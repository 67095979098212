import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { themeSelect } from "../../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../../shared/functions/Functions";
import GalleryCardPreview from "../../../../shared/gallery/GalleryCardPreview";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import firstViewSlice from "../../../../store/category/firstView";
import secondViewPaginationSlice from "../../../../store/category/secondViewPagination";
import { IAllStates } from "../../../../store/rootReducer";
import i18n from "../../../../utils/translations/i18next";
import { IExport } from "../../../exports/dto/IExports";
import { ExportService } from "../../../exports/service";
import { WorkflowService } from "../../../workflow/service";
import ProductVariation from "../../components/ProductVariation";
import { ProductVariationForm } from "../../components/ProductVariationForm";
import { IProductsList, IProductVariation } from "../../dto/IProducts";
import { ProductsService } from "../../service";
import { ChatGptContent } from "./ChatGptContent";
import { ContentActions } from "./ContentActions";
import { ContentViewSide } from "./ContentViewSide";
import VariationUpdateForm from "./VariationUpdateForm";
import workflowLangSlice from "../../../../store/workflow/workflowLangSlice";
import { ImgsCarousel } from "../../../../shared/gallery/ImgsCarousel";
import ImageContainer from "../../../../shared/gallery/ImageContainer";
import changeLocationSlice from "../../../../store/category/changeLocation";
import { BlockService } from "../../../block/service";
// title bio null
const variationInitial = {
  title: null,
  lang: "",
  bullets: "",
  introText: "",
  description: "",
  subtitle: "",
  blocks: {},
} as any;
interface IMessage {
  content: string;
  role: "assistant" | "user";
  sent?: Date;
}
const getAllVariations = ({
  variationPagination,
  id,
  setVariationList,
  setTotalPages,
}: any) => {
  ProductsService.getAllVariations({
    ...variationPagination,
    product: Number(id),
  })
    .then((response) => {
      setVariationList(response?.data);
      setTotalPages(response?.totalPages);
    })
    .catch((error) => ErrorToast(error));
};

interface Props {
  isTranslator?: boolean;
  isWorkflowUpdate?: boolean;
  selectedProductId?: number;
  setSelectedProductId?: React.Dispatch<SetStateAction<number>> | undefined;
  translationLangTab?: string | undefined;
}

export const ContentUpdate = ({
  isTranslator = false,
  isWorkflowUpdate = false,
  selectedProductId,
  setSelectedProductId,
  translationLangTab,
}: Props) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [allBlocks, setAllBlocks] = useState([] as any);
  const workflowLang = useSelector((state: IAllStates) => state.workflowLang);
  const translationStep = window.location.pathname?.includes("step6");
  const { id } = useParams();
  const workflowData = useSelector((state: IAllStates) => state.workflow);
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const urlLang =
    search.split("=").at(-1) ?? 0 !== 0 ? search.split("=").at(-1) : "first";
  const { t } = useTranslation();
  const [isListView, setIsListView] = useState(true);
  const [twoColumnView, setTwoColumnView] = useState(true);
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [variationPagination, setVariationPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [recommendedLanguage, setRecommendedLanguage] = useState("");
  const [correctSourceLang, setCorrectSourceLang] = useState("");
  const [correctRightColLang, setCorrectRightColLang] = useState("");
  const [variationList, setVariationList] = useState<IProductVariation[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentVariation, setCurrentVariation] = useState<IProductVariation>(
    variationInitial as any
  );
  const { state: pagination }: any = useLocation();
  const [page, setPage] = useState(1);
  const tab = "BASE_DATA";
  const location = useLocation();
  const basicContentEdit = location?.pathname?.includes(
    "products/edit-content"
  );
  const [showVariationModal, setShowVariationModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<IProductsList>();
  const { state: close }: any = useLocation();
  const [certainBlocks, setCertainBlocks] = useState([]);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );

  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [exportsList, setExportsList] = useState<IExport[]>([]);
  const [exportData, setExportData] = useState<{
    clientExport: number;
  }>();
  const [field, setField] = useState("");
  const firstView = useSelector((state: IAllStates) => state.firstView);
  const dispatch = useDispatch();
  const currentSwitchLanguage = localStorage.getItem("language");
  const currentLanguage = i18n?.language;

  const handleClose = () => {
    setShowVariationModal(false);
    setCurrentVariation(variationInitial as any);
  };
  const handleShow = () => {
    setShowVariationModal(true);
    setCertainBlocks(currentProduct?.block as any);
  };
  const handlePages = (updatePage: number) => {
    setVariationPagination({ ...variationPagination, page: updatePage });
    setPage(updatePage);
  };
  const navigateViewWorkflow = () => {
    if (isWorkflowUpdate) {
      navigate("");
    } else {
      navigate(`/app/products/edit-content/${id}`);
    }
  };
  const changeView1 = () => {
    setIsListView(!isListView);
    dispatch(firstViewSlice.actions.setFirstView(false));
    setTwoColumnView(false);
    navigateViewWorkflow();
  };
  const changeView2 = (id: number, lng: string, tab: string) => {
    dispatch(
      secondViewPaginationSlice.actions.setSecondViewPagination(location?.state)
    );
    setIsListView(!isListView);
    setTwoColumnView(true);
    dispatch(firstViewSlice.actions.setFirstView(true));
    if (!isTranslator) {
      navigate(`?lang=${lng}`);
      ProductsService.getProductLangById(
        Number(isWorkflowUpdate ? selectedProductId : id),
        tab,
        lng
      )
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          navigate(-1);
        });
    } else {
      WorkflowService.getTranslationProductById(
        Number(id),
        Number(selectedProductId)
      )
        .then((response) => {
          setCurrentProduct(response);

          setCorrectSourceLang(response?.workflow?.sourceLang);
          setCorrectRightColLang(response?.workflow?.language[0]);
        })
        .catch((error) => {
          ErrorToast(error);
          navigate(-1);
        });
    }
  };
  const [currentVariationTranslation, setCurrentVariationTranslation] =
    useState<IProductVariation>();

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >,
    language: string
  ) => {
    // @ts-ignore
    const { value, name } = event.target;
    dispatch(changeLocationSlice.actions.setChangeLocation(true));
    setCurrentProduct((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [name]: value,
        },
      },
    }));
  };
  const changeVariationHandler = (
    event: React.ChangeEvent<HTMLInputElement | ContentEditableEvent>
  ) => {
    const { value, name } = event.target as HTMLInputElement;
    setCurrentVariation((prevState) => ({ ...prevState, [name]: value }));
  };

  const changeBlocknHandler = (value: string, blockId: number) => {
    setCurrentVariation((prevState) => ({
      ...prevState,
      blocks: {
        ...prevState?.blocks,
        [blockId]: value,
      },
    }));
  };

  const handleSubmit = (
    event: React.FormEvent,
    language: string,
    checkNext = false
  ) => {
    event.preventDefault();
    if (!isTranslator) {
      ProductsService.updateProductContentById(
        {
          ...currentProduct,
          similar: !!currentProduct?.similar,
          lang: language,
          bullets: currentProduct?.translation?.[language]?.bullets,
          introText: currentProduct?.translation?.[language]?.introText,
          description: currentProduct?.translation?.[language]?.description,
          blocks: currentProduct?.translation?.[language]?.blocks,
        } as any,
        Number(isWorkflowUpdate ? selectedProductId : id),
        isWorkflowUpdate,
        Number(id)
      )
        .then((response) => {
          setMessages([]);
          dispatch(changeLocationSlice.actions.setChangeLocation(false));
          if (isWorkflowUpdate && checkNext) {
            WorkflowService.getNextAttributesWorkflow(
              Number(id),
              "COPY_WRITING"
            )
              .then((response) => {
                if (!response.nextProduct?.id) {
                  navigate(`/app/workflow/step5/${id}`);
                } else {
                  navigate(
                    `/app/workflow/step5/${response.nextProduct?.id}/${id}`
                  );
                  window.scrollTo(0, 0);
                }
              })
              .catch((error) => ErrorToast(error));
          }
          // navigate(1);
          SuccessToast(`${t("toasts.successfully_updated")} ${response.title}`);
          setTriggerUpdate((prev) => !prev);
        })
        .catch((error) => ErrorToast(error));
    } else {
      WorkflowService.updateTranslationProductById(
        {
          ...currentProduct,
          lang: language,
          bullets: currentProduct?.translation?.[language]?.bullets,
          introText: currentProduct?.translation?.[language]?.introText,
          description: currentProduct?.translation?.[language]?.description,
          blocks: currentProduct?.translation?.[language]?.blocks,
        } as any,
        Number(selectedProductId),
        Number(id)
      )
        .then((response) => {
          setMessages([]);
          dispatch(changeLocationSlice.actions.setChangeLocation(false));
          if (checkNext) {
            console.log("getNextTranslation");
            WorkflowService.getNextTranslationWorkflow(Number(id))
              .then((response) => {
                console.log(response);
                if (!response.nextProduct?.id) {
                  navigate(`/app/workflow/step6/${id}`);
                } else {
                  //ovdje setujem next lang
                  localStorage.setItem(
                    "translationNextLang",
                    response?.nextProduct?.recommendedLanguage ?? ""
                  );
                  console.log(
                    response?.nextProduct?.recommendedLanguage,
                    "response?.nextProduct?.recommendedLanguage"
                  );

                  dispatch(workflowLangSlice.actions.setWorkflowLang("de"));
                  navigate(
                    `/app/workflow/step6/${response.nextProduct?.id}/${id}`
                  );
                  window.scrollTo(0, 0);
                }
              })
              .catch((error) => ErrorToast(error));
          }
          // navigate(1);
          window.scrollTo(0, 0);
          SuccessToast(`${t("toasts.successfully_updated")} ${response.title}`);
          setTriggerUpdate((prev) => !prev);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const handleSaveVariation = () => {
    if (currentVariation?.id) {
      console.log("error");
    } else {
      ProductsService.createVariation({
        product: currentProduct?.id as number,
        lang: currentLanguage,
        title: currentVariation?.title,
        subtitle: currentVariation?.subtitle,
        bullets: currentVariation?.bullets,
        introText: currentVariation?.introText,
        blocks: currentVariation?.blocks,
        description: currentVariation?.description,
      })
        .then((response) => {
          SuccessToast(
            `${t("toasts.successfully_created")} ${
              response?.translation?.[currentLanguage as string]?.title
            }`
          );
          // setCurrentVariation(variationInitial);
          getAllVariations({
            variationPagination: variationPagination,
            id: currentProduct?.id,
            setVariationList: setVariationList,
            setTotalPages: setTotalPages,
          });
          handleClose();
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const checkHref = () => {
    if (
      currentProduct?.sourceUrl === null ||
      currentProduct?.sourceUrl === "" ||
      currentProduct?.sourceUrl === undefined
    ) {
      return "/403";
    } else if (
      currentProduct?.sourceUrl?.includes("https://") ||
      currentProduct?.sourceUrl?.includes("http://")
    ) {
      return currentProduct?.sourceUrl;
    } else {
      return `https://${currentProduct?.sourceUrl}`;
    }
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>,
    language: string,
    fieldName: string
  ) {
    setSourceLanguage(selectedSingle?.value as string);
    setTargetLanguage(language as string);
    setField(fieldName as string);
  }

  const translateAll = () => {
    setIsLoading(true);
    ProductsService.translateAll(
      isWorkflowUpdate ? Number(selectedProductId) : Number(id),
      { sourceLanguage: sourceLanguage }
    )
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_translated")}`);
        setTriggerUpdate((prev) => !prev);
      })
      .catch((error) => {
        ErrorToast(error);
      })
      .finally(() => setIsLoading(false));
  };

  const selectExportHandler = (e: any) => {
    setExportData({ clientExport: +e.value });
  };

  const exportClickHandler = () => {
    ExportService.exportProduct(exportData, +id!)
      .then((res) => {
        SuccessToast(`${t("toasts.successfully_exported")}`);
      })
      .catch((err) => console.log(err));
  };
  const handleCloseContent = () => {
    navigate("/app/products/content", {
      state: (location.state as any)?.pagination,
    });
    // navigate("/app/pending-products/imported", { state: { pagination: pagination?.pagination ?? {} } });
  };

  useEffect(() => {
    //
    //
    if (id && tab && !isTranslator) {
      ProductsService.getProductById(
        +id,
        tab,
        isWorkflowUpdate,
        selectedProductId
      )
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          navigate(-1);
        });
    } else {
      WorkflowService.getTranslationProductById(
        Number(id),
        Number(selectedProductId)
      )
        .then((response) => {
          setCurrentProduct(response);
          setRecommendedLanguage(response?.recommendedLanguage);
        })
        .catch((error) => {
          ErrorToast(error);
          navigate(-1);
        });
    }
  }, [id, isWorkflowUpdate, triggerUpdate, selectedProductId]);
  //bio jos workflowLang

  useEffect(() => {
    if (translationStep) {
      dispatch(firstViewSlice.actions.setFirstView(translationStep));
      changeView2(
        Number(id),
        translationLangTab ??
          localStorage.getItem("language") ??
          ("en" as string),
        "CONTENT"
      );
    } else {
      dispatch(firstViewSlice.actions.setFirstView(false));
    }
  }, []);

  useEffect(() => {
    BlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setAllBlocks(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    id &&
      getAllVariations({
        variationPagination: variationPagination,
        id: isWorkflowUpdate ? Number(selectedProductId) : +id,
        setVariationList: setVariationList,
        setTotalPages: setTotalPages,
      });
  }, [
    id,
    JSON.stringify(variationPagination),
    triggerUpdate,
    selectedProductId,
  ]);
  useEffect(() => {
    ExportService.getAllExports({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setExportsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  // currentProduct
  const navBaseData = () => {
    if (close?.close === "export") {
      navigate(`/app/products/base-data/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/base-data/${id}`, { state: pagination });
    }
  };
  //  {
  //   navigate(`/app/products/base-data/${id}`, { state: pagination });
  // };
  const navAttributes = () => {
    if (close?.close === "export") {
      navigate(`/app/products/attributes/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/attributes/${id}`, { state: pagination });
    }
  };

  const handleCheckVariation = (id: number | null) => {
    setCurrentProduct((prev: any) => ({ ...prev, variation: id }));
  };
  const backHandle = () => {
    if (close?.close === "export") {
      navigate(`/app/export/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
      //  navigate(-1)
    } else if (close?.close === "attributes") {
      navigate(`/admin/attributes/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
    } else if (handleCloseContent) {
      handleCloseContent();
    } else {
      navigate(-1);
    }
  };
  const backHandleCopywriting = () => {
    // window.location.reload();
    // setSelectedProductId && setSelectedProductId(0);
    if (window.location.pathname?.includes("step5")) {
      navigate(`/app/workflow/step5/${id}`);
    } else {
      navigate(`/app/workflow/step6/${id}`);
    }
  };

  return (
    <>
      {/* {isWorkflowUpdate && currentProduct && (
        <div className="d-flex 123 align-items-center mb-3">
          <div className="d-flex gap-3 align-items-center">
            <ImageContainer img={currentProduct?.productPictures ?? []} />
  
            <div>
              <h2>{currentProduct?.title}</h2>
              <p>{currentProduct?.eanCode}</p>
            </div>
          </div>
        </div>
      )} */}
      {/* aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */}
      <div
        className="card"
        style={
          false
            ? {
                position: "sticky",
                zIndex: "1001",
                top: "70px",
                backgroundColor: "white",
              }
            : {}
        }
      >
        <div className="card-body">
          {isWorkflowUpdate && currentProduct && (
            <div className="d-flex 123 align-items-center mb-3">
              <div className="d-flex gap-3 align-items-center">
                <ImageContainer img={currentProduct?.productPictures ?? []} />
                {/* <GalleryCardPreview
              img={
                currentProduct?.productPictures ||
                "/images/jk-placeholder-image.jpg"
              }
            /> */}
                {/* <ImgsCarousel /> aaaaaaaa */}
                {/* <CustomImageHandler
                classes="avatar-lg rounded-circle me-2"
                path={currentProduct?.productPictures[1]?.path ?? ""}
                alt="User logo"
              /> */}
                <div>
                  <h2>{currentProduct?.title}</h2>
                  <p>{currentProduct?.eanCode}</p>
                </div>
              </div>
            </div>
          )}
          {!isWorkflowUpdate && (
            <div className="d-flex align-items-end justify-content-between mb-3">
              <ul className="nav nav-pills nav-fill">
                <li onClick={() => navBaseData()}>
                  <span className="nav-link cursor-pointer text-dark">
                    {t("products.base_data_update")}
                  </span>
                </li>
                <li onClick={() => navAttributes()}>
                  <span className="nav-link cursor-pointer text-dark">
                    {t("products.attributes_update")}
                  </span>
                </li>
                <li>
                  <span className="nav-link active">
                    {t("products.content_update")}
                  </span>
                </li>
              </ul>
              <div
                className="d-flex align-items-end justify-content-between"
                style={{ zIndex: "1000" }}
              >
                <div className="" style={{ width: "226px" }}>
                  <h6 className="m-0 p-0 mb-2">{t("global.export")}:</h6>
                  <Select
                    className="react-select-field"
                    onChange={selectExportHandler}
                    options={exportsList.map((exportItem) => ({
                      label: exportItem.name,
                      value: exportItem.id,
                    }))}
                  />
                </div>
                <button
                  className="btn btn-primary ms-2"
                  onClick={exportClickHandler}
                >
                  {t("global.add_to_export")}
                </button>
              </div>
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between">
            <button
              onClick={() =>
                isWorkflowUpdate ? backHandleCopywriting() : backHandle()
              }
              className="btn btn-info d-flex align-items-center mb-3"
            >
              <i className="ri-arrow-left-line me-1"></i>
              {t("products.back")}
            </button>
            <a
              rel="noreferrer"
              target="_blank"
              // href={`${currentProduct?.sourceUrl === null ? "/403" : `${currentProduct?.sourceUrl}`} `}
              href={checkHref()}
            >
              <button className="btn btn-primary">
                {t("products.source_url")}
              </button>
            </a>
          </div>
          <div className="col-md-12  d-flex align-items-center justify-content-between">
            {!isWorkflowUpdate && currentProduct && (
              <div className="d-flex align-items-center mb-3">
                <div className="d-flex gap-3 align-items-center">
                  <ImageContainer img={currentProduct?.productPictures ?? []} />
                  {/* <CustomImageHandler 
            classes="avatar-lg rounded-circle me-2"
            path={currentProduct?.productPictures[1]?.path ?? ""}
            alt="User logo"
          /> */}
                  <div>
                    <h2>{currentProduct?.title}</h2>
                    <p>{currentProduct?.eanCode}</p>
                  </div>
                </div>
              </div>
            )}

            {
              // !isTranslator
              true && (
                <>
                  <div className="d-flex align-items-center">
                    {/* <button
              onClick={goBack}
              className="btn btn-info d-flex align-items-center me-2"
            >
              <i className="ri-arrow-left-line me-1"></i>
              {t("products.back")}
            </button> */}
                    <>
                      <div className="d-flex justify-content-end align-items-center my-1">
                        <>
                          <div className="">
                            <Select
                              className="custom_zindex_up react-select-field"
                              name="description"
                              theme={themeSelect}
                              placeholder="Translate from"
                              options={translationLanguageEnum?.map((lang) => ({
                                value: lang,
                                label: getCorrectLanguage(lang, t),
                              }))}
                              onChange={(e) => {
                                handleSelectSingle(
                                  e as any,
                                  sourceLanguage,
                                  "description"
                                );
                              }}
                            />
                          </div>
                          <button
                            onClick={translateAll}
                            className="btn btn-secondary d-flex align-items-center mx-2"
                          >
                            {isLoading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                              />
                            ) : (
                              <span>{t("global.translateAll")}</span>
                            )}
                          </button>
                        </>
                      </div>

                      <button
                        className="btn btn-primary d-flex align-items-center me-2"
                        data-bs-toggle="offcanvas"
                        data-bs-target={`#offcanvasRight-${id}`}
                        aria-controls={`offcanvasRight-${id}`}
                      >
                        <i className="ri-message-3-line me-1" />
                        {t("products.chatGpt")}
                      </button>
                      <div
                        className="offcanvas offcanvas-end main-height w-50"
                        id={`offcanvasRight-${id}`}
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div className="offcanvas-header">
                          <h5 id="offcanvasRightLabel">
                            {t("products.chatGpt")}
                          </h5>
                          <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="offcanvas-body">
                          <ChatGptContent
                            messages={messages}
                            setMessages={setMessages}
                          />
                        </div>
                      </div>
                    </>
                    <button
                      onClick={handleShow}
                      className="btn btn-success d-flex align-items-center"
                    >
                      <i className="ri-play-list-add-line me-1" />
                      {t("products.createVariation")}
                    </button>
                  </div>
                </>
              )
            }
          </div>
          {/* ovdjeeeee eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee*/}
          <div className="d-flex">
            {!basicContentEdit && (
              <div className="col-md-6 mb-2">
                <label className="m-0" htmlFor="content">
                  {t("side_bar.glossary")}
                </label>
                <div className="d-flex justify-content-between align-items-center my-1">
                  <div className="col-md-5">
                    <Select
                      isDisabled={true}
                      className="react-select-field"
                      placeholder={"Glossary client"}
                      theme={themeSelect}
                      // key={state?.id}
                      defaultValue={
                        localStorage.getItem("glossaryClient") && {
                          // label: clientName?.client as string,
                          label: JSON.parse(
                            localStorage.getItem("glossaryClient") as any
                          )?.client,
                        }
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex w-100 align-items-center justify-content-end mb-3">
              <button
                className={`btn btn${
                  firstView == true ? "-soft" : ""
                }-info  btn-sm`}
                onClick={() => changeView1()}
              >
                <i className="ri-list-check " />
              </button>
              <button
                className={`btn btn${
                  firstView !== true ? "-soft" : ""
                }-info btn-sm ms-2`}
                onClick={() =>
                  changeView2(
                    Number(id),
                    // recommendedLanguage ?? ("en" as string),
                    translationLangTab ??
                      localStorage.getItem("language") ??
                      ("en" as string),
                    "CONTENT"
                  )
                }
              >
                <i className="ri-bank-card-line" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */}
      {!currentVariation?.id ? (
        <Modal
          show={showVariationModal}
          onHide={handleClose}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("products.variation.createVariation")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProductVariationForm
              changeBlocknHandler={
                changeBlocknHandler as (value: string) => void
              }
              setCurrentVariation={setCurrentVariation}
              currentVariation={currentVariation}
              certainBlocks={certainBlocks}
              changeVariationHandler={
                changeVariationHandler as (
                  event: ContentEditableEvent | ChangeEvent<HTMLInputElement>
                ) => void
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={handleClose}>
              {t("global.cancel")}
            </Button>
            <Button variant="primary" onClick={handleSaveVariation}>
              {t("category.save")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <VariationUpdateForm
          currentVariation={currentVariationTranslation}
          variationId={currentVariation?.id}
          setCurrentVariation={setCurrentVariationTranslation}
          showVariationModal={showVariationModal}
          handleClose={handleClose}
          certainBlocks={certainBlocks}
          setTriggerUpdate={setTriggerUpdate}
        />
      )}
      {firstView ? (
        <>
          <div className="row">
            <div className="col-md-6">
              <ContentViewSide
                allBlocks={allBlocks}
                selectedProductId={selectedProductId}
                currentProduct={currentProduct as IProductsList}
                setCurrentProduct={setCurrentProduct}
                firstView={firstView || isTranslator}
                handleSubmit={handleSubmit}
                isWorkflowUpdate={isWorkflowUpdate}
                setTriggerUpdate={setTriggerUpdate}
                trigerUpdate={triggerUpdate}
                isTranslator={isTranslator}
                translationLangTab={workflowData?.sourceLang}
              />
            </div>
            <div className="col-md-6">
              <ContentActions
                allBlocks={allBlocks}
                recommendedLanguage={
                  currentProduct?.languages?.length === 1
                    ? recommendedLanguage
                    : ""
                }
                translationLangTab={translationLangTab}
                isWorkflowUpdate={isWorkflowUpdate}
                selectedProductId={selectedProductId}
                handleCloseContent={handleCloseContent}
                setTriggerUpdate={setTriggerUpdate}
                currentProduct={currentProduct as IProductsList}
                setCurrentProduct={setCurrentProduct}
                changeHandler={changeHandler}
                handleSubmit={handleSubmit}
                isTranslator={isTranslator}
                tabLanguages={(currentProduct as any)?.languages}
              />
              {!isTranslator &&
                variationList?.map((variation) => (
                  <ProductVariation
                    currentProduct={currentProduct as IProductsList}
                    handleCheckVariation={handleCheckVariation}
                    setCurrentVariationTranslation={
                      setCurrentVariationTranslation
                    }
                    setCurrentVariation={setCurrentVariation}
                    setVariationList={setVariationList}
                    handleShow={handleShow}
                    variation={variation}
                    key={variation?.id}
                  />
                ))}
              <div className="col-12 d-flex justify-content-end ">
                {totalPages <= 1 ? (
                  ""
                ) : (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`col-md-${isTranslator ? 12 : 9}`}>
            {isWorkflowUpdate && !isTranslator && (
              <div className="d-flex justify-content-end w-100 ">
                <div className="d-flex align-items-center mb-1">
                  <input
                    className="form-check-input m-0 cursor-pointer"
                    // defaultValue={state?.item as any}
                    // checked={!!state?.[item as keyof IExport]}
                    type="checkbox"
                    checked={currentProduct?.similar}
                    id="similar"
                    onChange={(e) =>
                      setCurrentProduct((prev: any) => ({
                        ...prev,
                        similar: e.target.checked,
                      }))
                    }
                  />
                  <label className="m-0 cursor-pointer ps-1" htmlFor="similar">
                    {t("global.similar")}
                  </label>
                </div>
                <div className="mx-3">
                  <input
                    className="form-check-input me-1"
                    type="radio"
                    name="format"
                    id={`variation-${id}`}
                    checked={null === currentProduct?.variation}
                    onChange={() =>
                      handleCheckVariation && handleCheckVariation(null)
                    }
                  />
                  <label
                    className="title-variation"
                    htmlFor={`variation-${id}`}
                  >
                    {t("workflow.select_product")}
                  </label>
                </div>
              </div>
            )}
            {/* teeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeees lang tab */}
            <ContentViewSide
              glossaryShow={true}
              allBlocks={allBlocks}
              handleCloseContent={handleCloseContent}
              currentProduct={currentProduct as IProductsList}
              selectedProductId={selectedProductId}
              setCurrentProduct={setCurrentProduct}
              firstView={firstView}
              handleSubmit={handleSubmit}
              isWorkflowUpdate={isWorkflowUpdate}
              setTriggerUpdate={setTriggerUpdate}
              trigerUpdate={triggerUpdate}
              isTranslator={isTranslator}
              translationLangTab={translationLangTab}
            />
          </div>
          {!isTranslator && (
            <div className="col-md-3">
              {variationList?.map((variation) => (
                <ProductVariation
                  currentProduct={currentProduct as IProductsList}
                  isWorkflowUpdate={isWorkflowUpdate}
                  handleCheckVariation={handleCheckVariation}
                  setCurrentVariationTranslation={
                    setCurrentVariationTranslation
                  }
                  setCurrentVariation={setCurrentVariation}
                  setVariationList={setVariationList}
                  handleShow={handleShow}
                  variation={variation}
                  key={variation?.id}
                />
              ))}
              <div className="col-12 d-flex justify-content-end ">
                {totalPages <= 1 ? (
                  ""
                ) : (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
      {/* <div className="d-flex justify-content-end w-100">
        {setSelectedProductId && !isTranslator && (
          <Button variant="info" onClick={() => setSelectedProductId(0)}>
            {t("products.back")}
          </Button>
        )}
      </div> */}
    </>
    // </PageTemplate>
  );
};
